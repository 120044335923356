import {
	useReducer,
	useMemo,
	useEffect,
	useCallback,
	createContext,
	useRef,
	useLayoutEffect,
} from 'react';
import coreReducer from 'reducers/coreReducer';
import { LocalStorage } from 'utils/localStorage';
export const BusinessVenuesContext = createContext({});

interface BusinessVenuesProviderType {
	id: number;
	[businessId: number]: any;
}
const initialState: BusinessVenuesProviderType[] = [];
const BusinessVenuesProvider = (props: any) => {
	const isMounted = useRef(false);
	const [state, dispatch] = useReducer(coreReducer, initialState);
	// const { } = state;

	const setNewState = useCallback(
		(value: any) => {
			// dispatch({
			// 	type: 'SET_NEW_STATE',
			// 	payload: { value },
			// });
		},
		[dispatch]
	);
	const set = useCallback(
		(key: string, value: any) => {
			LocalStorage.set('BusinessVenues', {
				[key]: value,
			});
			dispatch({
				type: 'ADD_STATE',
				key,
				payload: { value },
			});
		},
		[dispatch]
	);
	const update = useCallback(
		(key: string, value: any) => {
			const payloadValue = state[key].map((x: BusinessVenuesProviderType) => {
				return x.id == value.id ? value : x;
			});
			LocalStorage.set('BusinessVenues', {
				...state,
				[key]: payloadValue,
			});
			dispatch({
				type: 'ADD_STATE',
				key,
				payload: { value: payloadValue },
			});
		},
		[dispatch, state]
	);
	const get =
		useCallback(
			(key?: string) => {
				if (state.length <= 0 || Object.keys(state).length <= 0) {
					if (LocalStorage.get('BusinessVenues')) {
						setNewState(LocalStorage.get('BusinessVenues'));
						if (key) {
							return state[key] ?? false;
						} else {
							return state;
						}
					}
					return false;
				} else {
					if (key) {
						return state[key] ? state[key] : false
					} else {
						return state;
					}
				}

				// if (key) {
				// 	if (state[key]) return state[key];
				// 	else if (
				// 		LocalStorage.get('BusinessVenues') &&
				// 		LocalStorage.get('BusinessVenues')[key]
				// 	) {
				// 		set('BusinessVenues', LocalStorage.get('BusinessVenues'));
				// 		return state[key];
				// 		// return LocalStorage.get('BusinessVenues')[key];
				// 	} else {
				// 		return false;
				// 	}
				// 	// return state[key] ?? false;
				// } else {
				// 	return Object.keys(state).length ? state : false;
				// }
			},
			[state]
		);

	// 		// if (key) {
	// 		// 	if (state[key]) return state[key];
	// 		// 	else if (
	// 		// 		LocalStorage.get('BusinessVenues') &&
	// 		// 		LocalStorage.get('BusinessVenues')[key]
	// 		// 	) {
	// 		// 		set('BusinessVenues', LocalStorage.get('BusinessVenues'));
	// 		// 		return state[key];
	// 		// 		// return LocalStorage.get('BusinessVenues')[key];
	// 		// 	} else {
	// 		// 		return false;
	// 		// 	}
	// 		// 	// return state[key] ?? false;
	// 		// } else {
	// 		// 	return Object.keys(state).length ? state : false;
	// 		// }
	// 	},
	// 	[state]
	// );

	const reset = useCallback(() => {
		dispatch({
			type: 'RESET_STATE',
			payload: { initialState },
		});
	}, [dispatch]);

	const BusinessVenues = useMemo(() => {
		return {
			get,
			set,
			reset,
			update,
		};
	}, [get, reset, set, update]);
	return (
		<BusinessVenuesContext.Provider value={BusinessVenues}>
			{props.children}
		</BusinessVenuesContext.Provider>
	);
};

export default BusinessVenuesProvider;
